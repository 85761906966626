import {
  container,
  textImageWrapper,
  image,
  address,
} from "../footer/footer.module.scss"
import React from "react"
import logo from "../../assets/images/logo-blanc.svg"
import linkedin from "../../assets/images/LI-In-Bug.png"

export default function Footer(props) {
  return (
    <div className={container}>
      <div className={textImageWrapper}>
        <img src={logo} className={image} alt="Logo" />
        Sylvide ©2020
      </div>
      <div className={`${textImageWrapper} ${address}`}>
        150 Route de la Planche Pierre, 42310 Changy
        <a href={"https://www.linkedin.com/company/sylvide"}>
          <img src={linkedin} alt="Logo linkedin" className={image} />
        </a>
      </div>
    </div>
  )
}
