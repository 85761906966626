import React, { useState } from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import {
  container,
  containerMobile,
  menuBtn,
  open,
  menuBtn__burger,
  subMenu,
  menuItem,
  active,
} from "./menu.module.scss"
import {
  ContactPage,
  HomePage,
  NewsPage,
  ServicesPage,
  EnvironmentPage,
} from "../../components/enum/pages"

function Menu({ intl, active }) {
  const homeText = intl.formatMessage({ id: "menu.home" }).toUpperCase()
  const serviceText = intl.formatMessage({ id: "menu.services" }).toUpperCase()
  const environmentText = intl
    .formatMessage({ id: "menu.environment" })
    .toUpperCase()
  const newsText = intl.formatMessage({ id: "menu.news" }).toUpperCase()
  const contactText = intl.formatMessage({ id: "menu.contact" }).toUpperCase()
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div className={container}>
        <ListLink to="/" active={active === HomePage}>
          {homeText}
        </ListLink>
        <ListLink to="/services/" active={active === ServicesPage}>
          {serviceText}
        </ListLink>
        <ListLink to="/environment/" active={active === EnvironmentPage}>
          {environmentText}
        </ListLink>
        <ListLink to="/newsList/" active={active === NewsPage}>
          {newsText}
        </ListLink>
        <ListLink to="/contact/" active={active === ContactPage}>
          {contactText}
        </ListLink>
      </div>
      <div className={containerMobile}>
        <div
          className={`${menuBtn} ${menuOpen ? open : ""}`}
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          <div className={menuBtn__burger} />
        </div>

        <div className={`${subMenu} ${menuOpen ? open : ""}`}>
          <ListLink to="/" active={active === HomePage}>
            {homeText}
          </ListLink>
          <ListLink to="/services/" active={active === ServicesPage}>
            {serviceText}
          </ListLink>
          <ListLink to="/environment/" active={active === EnvironmentPage}>
            {environmentText}
          </ListLink>
          <ListLink to="/newsList/" active={active === NewsPage}>
            {newsText}
          </ListLink>
          <ListLink to="/contact/" active={active === ContactPage}>
            {contactText}
          </ListLink>
        </div>
      </div>
    </>
  )
}

export default injectIntl(Menu)

const ListLink = props => {
  let classes = `${menuItem}`
  if (props.active) {
    classes += ` ${active}`
  }
  return (
    <Link className={classes} to={props.to}>
      {props.children}
    </Link>
  )
}
