import React from "react"
import logo from "../../assets/images/logo.svg"
import Menu from "../menu/menu"
import { container, logoImage } from "./header.module.scss"
import { Link } from "gatsby-plugin-intl"

export default function Header(props) {
  return (
    <div className={container}>
      <Link to={"/"} className={logoImage}>
        <img className={logoImage} src={logo} alt="Logo" />
      </Link>
      <Menu active={props.active} />
    </div>
  )
}
