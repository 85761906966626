import React from "react"
import Header from "../header/header"
import Footer from "../footer/footer"
import { container } from "./layout.module.scss"

export default function Layout({ children, active }) {
  return (
    <div className={container}>
      <Header active={active} />
      {children}
      <Footer />
    </div>
  )
}
